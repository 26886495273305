import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios'; // Import axios for making API calls
import './App.css'; // Import custom CSS for styling

function SendGiftConfirmation() {
    const [senderEmail, setSenderEmail] = useState('');
    const [buyerCode, setBuyerCode] = useState('');
    const [receiverEmail, setReceiverEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post('/api/story/verifybuyercode', {
                senderEmail,
                buyerCode,
                receiverEmail,
                subject,
            });

            if (response.status === 200) {
                setMessage('Verification successful! An email has been sent to the receiver with redemption instructions.');
                setSuccess(true);
            } else {
                setMessage('Error: Please verify the buyer code or enter the correct sender email.');
                setSuccess(false);
            }
        } catch (error) {
            setMessage('Error: Please verify the buyer code or enter the correct sender email.');
            setSuccess(false);
        }
    };

    return (
        <section id="loginpagesection" className="section">

            {success ? (
                // Success message displayed when form is successfully submitted
                <div className="success-messagelogin">
                    {message}
                </div>
            ) : (
            <form onSubmit={handleSubmit}>
                <h3 id="sendgiftsectionheader">Gift Confirmation</h3>
                <div style={{ paddingTop: '20px' }}>
                    <div className="loginform">
                        <div className="subtitle">
                            Please confirm the email you used to purchase the package and enter the verification code.
                        </div>

                        {/* Sender Email */}
                        <div className="input-container ic2">
                            <input
                                id="senderEmail"
                                className="input"
                                type="email"
                                placeholder=" "
                                value={senderEmail}
                                onChange={(e) => setSenderEmail(e.target.value)}
                            />
                            <label htmlFor="senderEmail" className="placeholder">Sender Email</label>
                        </div>

                        {/* Buyer Code */}
                        <div className="input-container ic2">
                            <input
                                id="buyerCode"
                                className="input"
                                type="text"
                                placeholder=" "
                                value={buyerCode}
                                onChange={(e) => setBuyerCode(e.target.value)}
                            />
                            <label htmlFor="buyerCode" className="placeholder">Verification Code</label>
                        </div>

                        {/* Receiver Email */}
                        <div className="input-container ic2">
                            <input
                                id="receiverEmail"
                                className="input"
                                type="email"
                                placeholder=" "
                                value={receiverEmail}
                                onChange={(e) => setReceiverEmail(e.target.value)}
                            />
                            <label htmlFor="receiverEmail" className="placeholder">Receiver Email</label>
                        </div>

                        {/* Subject */}
                        <div className="input-container ic2">
                            <textarea
                                id="subject"
                                className="input larger-box-gift"
                                placeholder="Personalized Message"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                            />
                        </div>

                        {/* Submit Button */}
                        <button type="submit" className="submit">Submit</button>
                    </div>
                </div>
            </form>
            )}

            {/* Failure message displayed below the form if applicable */}
            {!success && message && (
                <div className="error-messagelogin">
                    {message}
                </div>
            )}
        
        </section>
    );

}

export default SendGiftConfirmation;

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios'; // Import axios for making API calls
import './App.css'; // Import custom CSS for styling

function ClaimGift({ isLoggedIn, userEmail}) {
    const [receiverEmail, setReceiverEmail] = useState('');
    const [receiverCode, setReceiverCode] = useState('');
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post('/api/story/verifyreceivercode', {
                receiverEmail,
                receiverCode,
                userEmail,
            });

            if (response.status === 200) {
                setMessage('You have redeemed your gift successfully! Please log out and log back in to see it working.');
                setSuccess(true);
            } else {
                setMessage('Error: Please verify the receiver code or email address.');
                setSuccess(false);
            }
        } catch (error) {
            setMessage('Error: Please verify the receiver code or email address.');
            setSuccess(false);
        }
    };

    return (
        <section className="section">
            <div>
                {isLoggedIn ? (
                    <div>
                        {success ? (
                            // Success message displayed when the form is successfully submitted
                            <div className="success-messagelogin">
                                {message}
                            </div>
                        ) : (
                            // Form displayed for redeeming the gift
                            <form onSubmit={handleSubmit}>
                                    <h3 id="sendgiftsectionheader">Claim Your Gift</h3>
                                <div style={{ paddingTop: '20px' }}>
                                    <div className="loginform">
                                        <div className="subtitle">
                                            Enter the email address where you received the gift and the verification code.
                                        </div>

                                        {/* Receiver Email */}
                                        <div className="input-container ic2">
                                            <input
                                                id="receiverEmail"
                                                className="input"
                                                type="email"
                                                placeholder=" "
                                                value={receiverEmail}
                                                onChange={(e) => setReceiverEmail(e.target.value)}
                                            />
                                            <label htmlFor="receiverEmail" className="placeholder">Receiver Email</label>
                                        </div>

                                        {/* Receiver Code */}
                                        <div className="input-container ic2">
                                            <input
                                                id="receiverCode"
                                                className="input"
                                                type="text"
                                                placeholder=" "
                                                value={receiverCode}
                                                onChange={(e) => setReceiverCode(e.target.value)}
                                            />
                                            <label htmlFor="receiverCode" className="placeholder">Verification Code</label>
                                        </div>

                                        {/* Submit Button */}
                                        <button type="submit" className="submit">Submit</button>
                                    </div>
                                </div>
                            </form>
                        )}

                        {/* Failure message displayed below the form if applicable */}
                        {!success && message && (
                            <div className="error-messagelogin">
                                {message}
                            </div>
                        )}
                    </div>
                ) : (
                    <section id="notloggedinadjusterforsectionforappjs" className="section">
                        <div>
                            You are currently not logged in.{' '}
                            <a href="/LoginPage">Go to the Login Page</a>
                        </div>
                    </section>
                )}
            </div>
        </section>
    );
}

export default ClaimGift;
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios'; // Import axios for making API calls
import './App.css'; // Import custom CSS for styling
import { Link } from 'react-router-dom';
import { LanguageContext } from './LanguageContext';
import translations from './translations';
import jwt_decode from 'jwt-decode';

function SendGift() {


    const [email, setEmail] = useState('');
    const [selectedPackage, setSelectedPackage] = useState('');
    const [redirecting, setRedirecting] = useState(false);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePackageSelect = (event) => {
        setSelectedPackage(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        // Check if email is entered and a package is selected
        if (email && selectedPackage) {
            setRedirecting(true); // Optionally, show loading or redirect message

            // Based on the selected package, construct the payment URL
            const pageTemplateId = '18722'; // Page template ID for PayPro Global

            let productId;
            if (selectedPackage === 'Gold-Package') {
                productId = '105137';
            } else if (selectedPackage === 'Silver-Package') {
                productId = '105136';
            }

            const paymentURL = `https://store.payproglobal.com/checkout?products[1][id]=${productId}&page-template=${pageTemplateId}&billing-email=${email}`;

            // Redirect to the constructed PayPro Global URL
            window.location.href = paymentURL;
        } else {
            alert('Please enter a valid email and select a package.');
        }
    };

    return (
        <section id="loginpagesection" className="section">
            <form onSubmit={handleSubmit}>
                <h3 id="sendgiftsectionheader">Send a gift</h3>
                <div style={{ paddingTop: '20px' }}>
                    <div className="loginform">
                        <div className="subtitle">Please enter your email and choose a package</div>

                        {/* Email input */}
                        <div className="input-container ic2">
                            <input
                                id="email"
                                className="input"
                                type="email"
                                placeholder=" "
                                value={email}
                                onChange={handleEmailChange}
                            />
                            <label htmlFor="email" className="placeholder">Email</label>
                        </div>

                        {/* Package selection */}
                        <div className="input-container ic2">
                            <select
                                id="package"
                                className="input"
                                value={selectedPackage}
                                onChange={handlePackageSelect}
                            >
                                <option value="">Select a package</option>
                                <option value="Gold-Package">Gold-Package 25 stories- $39.99</option>
                                <option value="Silver-Package">Silver-Package 10 stories-$16.99</option>
                            </select>
                            <label htmlFor="package" className="placeholder">Select Package</label>
                        </div>

                        {/* Submit button */}
                        <button type="submit" className="submit">Proceed to Payment</button>
                        <div className="subtitle">
                            <span>
                                Once transaction is completed, you will receive an email with next steps!
                            </span>
                        </div>
                    </div>
                </div>
            </form>
            {redirecting && <div className="success-messagelogin">Redirecting to payment...</div>}
        </section>
    );
}
export default SendGift;